import React, { useState } from "react";
import { Modal, Button, Row, Form } from "react-bootstrap";
import Select from 'react-select';
import { useTranslation } from "react-i18next";

const AccountDelete = ({ showModalDelete, setShowModalDelete }) => {
    const handleClose = () => setShowModalDelete(false);
    const [selectedOption, setSelectedOption] = useState('');
    const { t } = useTranslation();


const options = [
    { value: 'I have another account', label: 'I have another account' },
    { value: 'I want to create a new account', label: 'I want to create a new account' },
    { value: 'I do not use this account anymore', label: 'I do not use this account anymore' },
    { value: 'I get too many forgot password emails', label: 'I get too many forgot password emails' },
    { value: 'Security Concerns', label: 'Security Concerns' },
    { value: 'Privacy Concerns', label: 'Privacy Concerns' },
    { value: 'None of the above', label: 'None of the above' }
  ]

  const [isChecked, setIsChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (!isChecked) {
      console.log('Error: Please check the checkbox');
      return;
    }
    if (selectedValue === '') {
      console.log('Error: Please select an option');
      return;
    }
    console.log('Form submitted');
  };


  return (
    <Modal show={showModalDelete} onHide={handleClose} className="account-delete">

      <Modal.Header closeButton>
        <Modal.Title>
          <h4> {t("delete.heading")} </h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-3">
            <Form.Label> <b> {t("delete.label")} </b> </Form.Label>
            <Select
                className="select-style form-control"
                options={options}
                value={selectedOption}
                onChange={handleSelectChange}
            />
            {isSubmitted && !selectedOption && (
                <span className="error"> Please select an option </span>
            )}
        </Form.Group>
        
        <Row>
            <ul>
              <li> {t("delete.term1")}  </li>
              <li> {t("delete.term2")}  </li>
              <li> {t("delete.term3")}  </li>
            </ul>
        </Row>

        <Row>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  className="mb-3"
                  label="I have read and agree to the terms and conditions"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={!isChecked}>
                  {t("delete.youraccount")}  
              </Button>
            </Form>
        </Row>
        
      </Modal.Body>
      
    </Modal>
  );
};

export default AccountDelete;